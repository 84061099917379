import React from "react";
import styled from "styled-components";

export const IceSkating = (props) => {
  const { activeCastleUid } = props;

  // if nog editionUid from St Truiden 2024, display nothing
  if (activeCastleUid !== "097409031590") return null;

  return (
    <IceSkatingBox>
      <h5>Promo: ga ook schaatsen in Sint-Truiden!</h5>
      <p>
        Koop je combi ticket en ga naast Kerstmagie ook op een moment naar keuze
        heerlijk schaatsen in het centrum van Sint-Truiden.{" "}
        <a
          href="https://www.shopandthecity.be/truiense-nostalgie/"
          target="_blank"
          rel="noreferrer"
        >
          Meer informatie
        </a>
      </p>
      <img src="/images/shop_the_city.png" alt="Shop & The City" />
    </IceSkatingBox>
  );
};

const IceSkatingBox = styled.div`
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #e42175;

  h5 {
    color: #e42175;
    font-size: 1.1rem;
  }
  p {
    font-size: 0.9rem;
    line-height: 1.4;
  }
  a {
    color: #e42175;
    font-weight: bold;
    text-decoration: none;

    &:visited {
      color: #e42175;
    }
  }

  img {
    max-width: 200px;
    width: 100%;
    display: block;
    margin: 0;
  }
`;
