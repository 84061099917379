export const formatCurrency = (number, lang) => {
  const langToFormat = lang ?? "nl";
  return number.toLocaleString(langToFormat, {
    style: "currency",
    currency: "EUR",
    currencyDisplay: "narrowSymbol",
  });
};

export const getDiscountPrice = (type, cart) => {
  if (!type || !cart || !type.price) return 0;
  const originalPrice = type.price;
  const typeId = type.id;

  const deals = cart.deals;

  const discountPrice = deals.reduce((price, deal) => {
    if (deal.times_fulfilled === 0) return price;

    const bestRewardsPrice = deal.rewards.reduce((rewardPrice, reward) => {
      if (
        (reward.types && !reward.types.includes(typeId)) ||
        reward.reward !== "order_discount"
      )
        return rewardPrice;

      let tempPrice = rewardPrice;
      if (reward.discount_type === "fixed") {
        tempPrice = rewardPrice - +reward.discount_amount;
      }
      if (reward.discount_type === "percentage") {
        const discount =
          Math.round(rewardPrice * (+reward.discount_amount / 100) * 100) / 100;
        tempPrice = rewardPrice - discount;
      }

      tempPrice = Math.max(0, tempPrice);

      return tempPrice < rewardPrice ? tempPrice : rewardPrice;
    }, price);

    return bestRewardsPrice < price ? bestRewardsPrice : price;
  }, originalPrice);

  return discountPrice;
};
