import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import styled, { css } from "styled-components";
import { Helmet } from "react-helmet";
import ReactPixel from "react-facebook-pixel";

import { PageTitle } from "../components/PageTitle";
import { Button } from "../components/Button";
import { Preloader } from "../components/Preloader";
import { Alert } from "../components/Alert";
import { Error } from "../components/Error";
import { VoucherBox } from "../components/VoucherBox";
import { HeritaBox } from "../components/HeritaBox";
import { Checkbox } from "../components/Checkbox";
import { IceSkating } from "../components/IceSkating";

import { formatCurrency, getDiscountPrice } from "../lib/helpers";

export const PageCart = (props) => {
  const {
    activeCastle,
    selectedTimeslot,
    store,
    cart,
    cartLoading,
    fetchCart,
  } = props;

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(undefined);
  const [soldoutTypes, setSoldoutTypes] = useState([]);
  const [quantityChange, setQuantityChange] = useState(0);

  const [showVoucher, setShowVoucher] = useState(false);
  const [showHerita, setShowHerita] = useState(false);
  const [heritaDeal, setHeritaDeal] = useState(null);

  const [confirmedLanguage, setConfirmedLanguage] = useState(false);
  const activeLanguage = i18n.languages[0];
  const showLanguage = activeCastle.lang;

  useEffect(() => {
    if (!activeCastle || !cart) return;
    // Should also check if cart is empty...
    if (!selectedTimeslot && cart && !cart.items.types.length) {
      navigate(`/${activeLanguage}/${activeCastle.uri}`);
    }
    // Check if Herita Deal is active
    if (cart.deals && cart.deals.length) {
      const deal = cart.deals.find(
        (deal) => deal.id === "312999511225" // 2024
      );
      setHeritaDeal(deal);
    }
  }, [activeCastle, selectedTimeslot, cart]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Fetch show date for timeslot
    fetchShow();
  }, [selectedTimeslot, activeLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (store) {
      const fbPixel = store.integrations.find(
        (integration) => integration.type === "facebook_pixel"
      );
      if (fbPixel) {
        ReactPixel.init(fbPixel.credentials.pixel_id);
      }
    }
  }, [store]);

  const fetchShow = () => {
    if (!selectedTimeslot || !activeCastle || !cart) return;
    fetch(
      `/api/store/${activeCastle.uri}/${selectedTimeslot.id}?cart=${cart.cartid}&language=${activeLanguage}`
    )
      .then((response) => response.json())
      .then((data) => {
        setShow(data.show);
        setSoldoutTypes(
          data.show.types.filter((type) => type.status === "soldout")
        );
      })
      .catch((error) => {
        console.error("Error:", error);
        throw error;
      });
  };

  const handleSubmitCart = () => {
    if (!cart.items.types.length) return;
    const checkoutUrl =
      cart.fastlane_url + "?s=" + encodeURIComponent(window.location.href);
    window.location.href = checkoutUrl;
  };

  const canCheckout = () => {
    if (activeLanguage === showLanguage || confirmedLanguage)
      return !!cart.items.types.length;
    return false;
  };

  const isCartFilled = () => {
    return !!cart.items.types.length;
  };

  const cartHasMultipleShows = () => {
    if (!cart.items.types.length) return false;
    const shows = [];
    cart.items.types.forEach((cartitem) => {
      if (cartitem.show && !shows.includes(cartitem.show.id))
        shows.push(cartitem.show.id);
    });
    return shows.length > 1;
  };

  const handleDeleteItem = (cartitem) => {
    const queryParams = {};
    if (cartitem.show) {
      queryParams.show = cartitem.show.id;
    }
    if (cartitem.voucher) {
      queryParams.voucher = cartitem.voucher.code;
    }
    const qs = new URLSearchParams(queryParams);
    const queryString = qs.toString();
    const deleteUrl = `/api/cart/${cart.cartid}/types/${cartitem.id}?${queryString}`;
    fetch(deleteUrl, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) => {
        fetchCart();
      })
      .catch((error) => {
        console.error("Error:", error);
        throw error;
      });
  };

  const handleShowHerita = () => {
    setShowHerita(true);
    setShowVoucher(false);
  };
  const handleShowVoucher = () => {
    setShowVoucher(true);
    setShowHerita(false);
  };
  const handleCloseBoxes = () => {
    setShowHerita(false);
    setShowVoucher(false);
  };

  const isHeritaCastle = () => {
    if (!activeCastle || !store || !selectedTimeslot || !cart) return false;
    // Enable Herita for Laarne
    return activeCastle.uid === "763469504010"; // Laarne 2024
  };

  const getHeritaTypes = () => {
    if (!heritaDeal) return [];
    const types = heritaDeal.rewards
      .filter((reward) => reward.reward === "unlock_type")
      .map((reward) => reward.type);
    return types;
  };

  // Wait for active castle to be set
  if (!activeCastle || !store || !selectedTimeslot || !cart) {
    return null;
  }

  const dateStart = new Date(selectedTimeslot.date.start.replace(/-/g, "/"));

  const handleDeleteDeal = async (coupon) => {
    fetch(`/api/cart/${cart.cartid}/coupon/${coupon}`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) => {
        fetchCart();
      })
      .catch((error) => {
        console.error("Error:", error);
        throw error;
      });
  };

  return (
    <>
      <Helmet>
        <title>
          {i18n.languages[0] === "fr"
            ? `Réservation - ${activeCastle.name} - Magie de Noël`
            : `Reservatie - ${activeCastle.name} - Kerstmagie 2022`}
        </title>
      </Helmet>
      <PageTitle
        linkTo={`/${activeCastle.uri}`}
        linkLabel={t("cart_page.go_to_shows")}
        icon="icon-tickets.svg"
        subtitle={`${activeCastle.name} - ${activeCastle.city}`}
        title={t("cart_page.title")}
      />
      <CartGrid>
        {show && (
          <div>
            <ShowTitle>
              <img src="/images/icon-calendar.svg" alt="" />
              <h5>
                {dateStart.toLocaleString(i18n.language, {
                  dateStyle: "full",
                  timeStyle: "short",
                })}
              </h5>
            </ShowTitle>
            {!!soldoutTypes.length && (
              <Alert danger>
                {t("cart_page.soldout_1")}
                {soldoutTypes[0].name}
                {t("cart_page.soldout_2")}
              </Alert>
            )}
            {heritaDeal && (
              <HeritaMember>
                <p>Je bent aangemeld als Herita lid.</p>
              </HeritaMember>
            )}

            <IceSkating activeCastleUid={activeCastle.uid} />

            <TypeList>
              {getHeritaTypes().map((type) => {
                return (
                  <TypeComponent
                    key={type.id}
                    selectedTimeslot={selectedTimeslot}
                    cart={cart}
                    cartLoading={cartLoading}
                    type={type}
                    show={show}
                    castle={activeCastle}
                    deal={heritaDeal}
                    fetchCart={fetchCart}
                    quantityChange={quantityChange}
                    setQuantityChange={setQuantityChange}
                  />
                );
              })}
              {show.types.map((type) => {
                return (
                  <TypeComponent
                    key={type.id}
                    selectedTimeslot={selectedTimeslot}
                    cart={cart}
                    cartLoading={cartLoading}
                    type={type}
                    show={show}
                    castle={activeCastle}
                    fetchCart={fetchCart}
                    quantityChange={quantityChange}
                    setQuantityChange={setQuantityChange}
                  />
                );
              })}
              {store.channel.types.length > 0 && (
                <>
                  <ExtrasTitle>
                    {/* <img src="/images/icon-calendar.svg" alt="" /> */}
                    <h5>Extra's</h5>
                  </ExtrasTitle>
                  {store.channel.types.map((type) => {
                    return (
                      <TypeComponent
                        key={type.id}
                        selectedTimeslot={selectedTimeslot}
                        cart={cart}
                        cartLoading={cartLoading}
                        type={type}
                        castle={activeCastle}
                        fetchCart={fetchCart}
                        quantityChange={quantityChange}
                        setQuantityChange={setQuantityChange}
                      />
                    );
                  })}
                </>
              )}
            </TypeList>
            {!heritaDeal && isHeritaCastle() && (
              <PromoGroup>
                {showHerita && (
                  <HeritaBox
                    closeHeritaBox={handleCloseBoxes}
                    cart={cart}
                    fetchCart={fetchCart}
                  />
                )}
                {!showHerita && (
                  <Button block type="gray" onClick={handleShowHerita}>
                    Registreer hier gratis met Herita lidnummer
                  </Button>
                )}
              </PromoGroup>
            )}
            <PromoGroup>
              {showVoucher && (
                <VoucherBox
                  closeVoucherBox={handleCloseBoxes}
                  show={show}
                  cart={cart}
                  fetchCart={fetchCart}
                />
              )}
              {!showVoucher && (
                <Button block type="gray" onClick={handleShowVoucher}>
                  {t("cart_page.got_voucher")}
                </Button>
              )}
            </PromoGroup>
          </div>
        )}
        <CartOverview>
          <h3>{t("cart_page.your_reservation")}</h3>
          <CartItems>
            {cart.items.types.map((cartitem, index) => {
              const showDate = cartitem.show
                ? new Date(cartitem.show.date.start.replace(/-/g, "/"))
                : undefined;

              return (
                <CartItem key={index}>
                  <CartItemInfo>
                    <CartItemName>
                      {cartitem.quantity} x {cartitem.name}
                    </CartItemName>
                    <CartItemShow>
                      {showDate &&
                        showDate.toLocaleString(i18n.language, {
                          dateStyle: "full",
                          timeStyle: "short",
                        })}
                    </CartItemShow>
                    {cartitem.voucher && (
                      <CartItemVoucher>
                        Voucher - {cartitem.voucher.code}
                      </CartItemVoucher>
                    )}
                  </CartItemInfo>
                  <CartItemPrice>
                    {cartitem.price - cartitem.discount > 0
                      ? formatCurrency(
                          (cartitem.price - cartitem.discount + cartitem.fee) *
                            cartitem.quantity,
                          i18n.language
                        )
                      : t("cart_page.free")}
                  </CartItemPrice>
                  <RemoveButton onClick={() => handleDeleteItem(cartitem)}>
                    x
                  </RemoveButton>
                </CartItem>
              );
            })}
            {cart.deals.length > 0 && (
              <CartDeals>
                {cart.deals.map((deal, index) => {
                  return (
                    <CartDeal key={deal.id}>
                      <div>
                        <h4>{deal.name}</h4>
                        <p>{deal.description}</p>
                      </div>
                      <div>
                        <RemoveButton
                          onClick={() => handleDeleteDeal(deal.coupon)}
                        >
                          x
                        </RemoveButton>
                      </div>
                    </CartDeal>
                  );
                })}
              </CartDeals>
            )}
          </CartItems>
          {cartHasMultipleShows() && (
            <Alert>{t("cart_page.alert_multiple_shows")}</Alert>
          )}
          <CartCheckList>
            {isCartFilled() && (
              <>
                <CartCheck>
                  <CartCheckIcon src="/images/icon-castle.svg" />
                  <CartCheckInfo>
                    <CartCheckInfoTitle>
                      {t("cart_page.location")}
                    </CartCheckInfoTitle>
                    <CartCheckInfoValue>{`${activeCastle.name} - ${activeCastle.city}`}</CartCheckInfoValue>
                  </CartCheckInfo>
                </CartCheck>
                <CartCheck>
                  <CartCheckIcon src="/images/icon-pay.svg" />
                  <CartCheckInfo>
                    <CartCheckInfoTitle>
                      {t("cart_page.total")}
                    </CartCheckInfoTitle>
                    <CartCheckInfoValue>
                      {cart.summary.total
                        ? "€ " + cart.summary.total.toFixed(2)
                        : t("cart_page.free")}
                    </CartCheckInfoValue>
                  </CartCheckInfo>
                </CartCheck>
                {activeLanguage !== showLanguage && (
                  <Checkbox
                    value={confirmedLanguage}
                    onChange={() => setConfirmedLanguage(!confirmedLanguage)}
                  >
                    {t("cart_page.lang_confirm")}
                  </Checkbox>
                )}
              </>
            )}
          </CartCheckList>
          {!isCartFilled() && <Alert>{t("cart_page.alert_empty_cart")}</Alert>}
          <Button disabled={!canCheckout()} block onClick={handleSubmitCart}>
            {t("cart_page.book")}
          </Button>
        </CartOverview>
      </CartGrid>
    </>
  );
};

const TypeComponent = (props) => {
  const {
    type,
    cart,
    cartLoading,
    show,
    castle,
    fetchCart,
    quantityChange,
    setQuantityChange,
    selectedTimeslot,
    deal,
  } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [selfQuantityChange, setSelfQuantityChange] = useState(0);
  const { t, i18n } = useTranslation();

  const getQuantity = () => {
    const typeInCart = cart.items.types.find((cartitem) => {
      if (show) return cartitem.id === type.id && cartitem.show.id === show.id;
      return cartitem.id === type.id;
    });
    if (typeInCart) return typeInCart.quantity;
    return 0;
  };

  const handleMin = () => {
    const currentQuantity = getQuantity();
    if (currentQuantity === 0) return;
    modifyQuantity(-1);
  };

  const handlePlus = () => {
    modifyQuantity(1);
  };

  const modifyQuantity = (quantity) => {
    setLoading(true);
    setError(undefined);
    const putUrl = `/api/cart/${cart.cartid}/types/${type.id}`;
    if (quantity > 0) {
      ReactPixel.track("AddToCart", {
        content_ids: [type.id],
        content_type: "product",
        content_category: `Kerstmagie ${castle.city}`,
        content_name: type.name,
        value: type.price,
        currency: "EUR",
      });
    }
    fetch(putUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        show: show?.id,
        quantity: quantity,
        deal: deal?.id,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response
            .json()
            .then((data) => {
              throw data;
            })
            .catch((err) => {
              throw err;
            });
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setQuantityChange(quantityChange + quantity);
        setSelfQuantityChange(selfQuantityChange + quantity);
        setLoading(false);
        fetchCart();
      })
      .catch((error) => {
        if (typeof error.error !== "undefined") {
          setError(error.error);
        } else {
          console.error("Error", error);
          throw error;
        }
        setLoading(false);
      });
  };

  const virtualTypeStock = type.stock - selfQuantityChange;
  const virtualShowStock =
    selectedTimeslot.attendees.available - quantityChange;
  const virtualStock = Math.min(virtualTypeStock, virtualShowStock);

  const price = getDiscountPrice(type, cart);

  return (
    <Type key={type.id}>
      <div>
        <TypeName>{type.name}</TypeName>
        {type.status !== "soldout" && (
          <TypePrice>
            {price ? formatCurrency(price, i18n.language) : t("cart_page.free")}
            <sup>
              {type.fee
                ? " + " +
                  formatCurrency(type.fee, i18n.language) +
                  t("cart_page.fee")
                : ""}
            </sup>
          </TypePrice>
        )}
        {type.status === "low" && (
          <TypeStatus>
            {virtualStock} {t("cart_page.status_low")}
          </TypeStatus>
        )}
        {type.status === "soldout" && (
          <TypeStatus danger>{t("cart_page.status_soldout")}</TypeStatus>
        )}
      </div>
      <TypeQuantity>
        <QuantityButton onClick={handleMin} disabled={getQuantity() === 0}>
          -
        </QuantityButton>
        <QuantityColumn>
          {!loading && !cartLoading && <Quantity>{getQuantity()}</Quantity>}
          {(loading || cartLoading) && <Preloader />}
        </QuantityColumn>
        <QuantityButton onClick={handlePlus} disabled={virtualStock === 0}>
          +
        </QuantityButton>
      </TypeQuantity>
      <Error error={error} onClose={() => setError(undefined)} />
    </Type>
  );
};

const CartGrid = styled.div`
  display: grid;
  grid-template-columns: auto 400px;
  grid-gap: 32px;

  @media (max-width: 1260px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 1100px) {
    grid-template-columns: auto 400px;
  }

  @media (max-width: 860px) {
    grid-template-columns: 1fr;
  }
`;

const HeritaMember = styled.div`
  margin-bottom: 20px;
  background-color: ${(props) => props.theme.primary};
  color: #fff;
  font-size: 16px;
  padding: 16px 20px;
  border-radius: 4px;
  p {
    margin-bottom: 0px;
  }
`;

const ShowTitle = styled.div`
  margin-bottom: 20px;

  img {
    width: 24px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 12px;
  }
  h5 {
    display: inline-block;
    font-size: 20px;
    margin-bottom: 0px;
  }
`;

const ExtrasTitle = styled.div`
  margin-bottom: 20px;
  margin-top: 30px;

  h5 {
    display: inline-block;
    font-size: 18px;
    margin-bottom: 0px;
  }
`;

const TypeList = styled.div`
  margin-bottom: 32px;
`;

const Type = styled.div`
  background-color: #fff;
  box-sizing: border-box;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 16px;
  align-items: center;
`;

const TypeName = styled.div`
  font-weight: bold;
  margin-bottom: 4px;
`;

const TypePrice = styled.div`
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 4px;

  sup {
    font-size: 12px;
  }
`;

const TypeStatus = styled.div`
  font-weight: normal;
  font-size: 14px;

  ${(props) =>
    props.danger &&
    css`
      color: ${(props) => props.theme.dangerDark};
      font-weight: 600;
    `}
`;

const TypeQuantity = styled.div`
  display: grid;
  grid-template-columns: 36px 50px 36px;
  align-items: center;
  justify-content: right;
`;

const QuantityColumn = styled.div`
  text-align: center;
`;

const QuantityButton = styled.button`
  width: 36px;
  height: 36px;
  border-radius: 4px;
  margin: 0px;

  border: none;
  padding: 0;
  background-color: ${(props) => props.theme.primary};
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;

  font-weight: bold;
  transition: all 200ms ease;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${(props) => props.theme.primaryLight};
  }

  ${(props) =>
    props.disabled
      ? css`
          opacity: 0.5;
          pointer-events: none;
          cursor: default;
        `
      : ""}
`;

const Quantity = styled.div`
  font-weight: bold;
  text-align: center;
`;

const CartOverview = styled.div`
  background-color: #fff;
  padding: 32px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  align-self: start;
`;

const CartItems = styled.div`
  margin-bottom: 32px;
`;

const CartItem = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: auto auto 16px;
  padding: 8px 0px;
  border-bottom: 1px solid #ccc;
  align-items: center;
`;

const CartItemInfo = styled.div``;
const CartItemName = styled.div`
  font-size: 16px;
  margin-bottom: 4px;
  font-weight: bold;
`;
const CartItemShow = styled.div`
  font-size: 14px;
`;

const CartItemVoucher = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  border: 1px solid ${(props) => props.theme.text};
  display: inline-block;
  padding: 2px 8px;
  border-radius: 4px;
  margin-top: 6px;
`;
const CartItemPrice = styled.div`
  font-size: 16px;
  text-align: right;
`;

const RemoveButton = styled.button`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0px;
  background-color: ${(props) => props.theme.primary};
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  line-height: 1;
  font-weight: bold;
  transition: all 200ms ease;

  &:hover {
    background-color: ${(props) => props.theme.primaryLight};
  }
`;

const CartDeals = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  border: 1px solid ${(props) => props.theme.text};
  display: block;
  border-radius: 4px;
  margin-top: 12px;
`;

const CartDeal = styled.div`
  padding: 8px 8px;
  border-bottom: 1px solid #ccc;
  display: grid;
  grid-template-columns: auto 16px;
  grid-gap: 12px;

  &:last-child {
    border-bottom: none;
  }

  h4 {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  p {
    text-transform: none;
    font-size: 12px;
    font-weight: 400;
    margin-top: 6px;
    margin-bottom: 0;
  }
`;

const CartCheckList = styled.div`
  margin-bottom: 28px;
`;

const CartCheck = styled.div`
  display: grid;
  grid-template-columns: 32px auto;
  grid-gap: 16px;
  margin-bottom: 16px;
`;

const CartCheckIcon = styled.img`
  width: 32px;
  height: 32px;
`;

const CartCheckInfo = styled.div``;

const CartCheckInfoTitle = styled.div`
  margin-bottom: 2px;
`;

const CartCheckInfoValue = styled.div`
  font-weight: bold;
`;

const PromoGroup = styled.div`
  margin-bottom: 8px;
`;
